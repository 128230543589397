import React from 'react';
import { BlueBob, MaroonBlobOne, MaroonBlobTwo, YellowBlob } from '../assets/assets';

function Blobs({ leftBlobTop }) {
	return (
		<>
			<img className="absolute -right-32 bottom-[34%] h-48" src={MaroonBlobOne} alt="" />
			<img
				className={`absolute -left-28 h-52 ${leftBlobTop ? 'top-1/4' : 'bottom-1/4'}`}
				src={MaroonBlobTwo}
				alt=""
			/>
			<img className="absolute -bottom-20 h-40 left-[48%]" src={YellowBlob} alt="" />
			<img className="absolute -top-24 right-24 h-48" src={BlueBob} alt="" />
		</>
	);
}

export default Blobs;
