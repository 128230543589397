import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function HiddenButton() {
	const navigate = useNavigate();

	const [confirmOpen, setConfirmOpen] = useState(false);

	// Hidden Navigation
	return (
		<>
			<div className="fixed top-0 right-0 w-20 h-20" onClick={() => setConfirmOpen(true)}></div>
			{confirmOpen && (
				<div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-black bg-white p-8 z-50 flex flex-col items-center gap-4 rounded-lg">
					<p className="text-center">
						Are you sure you want to leave this page? <br></br> Navigating without showing an ambassador
						will invalidate any prizes won.
					</p>
					<div className="flex items-center gap-20">
						<button
							className="bg-red-500 text-white px-4 py-2 rounded-md text-lg"
							onClick={() => {
								navigate('/', { replace: true });
								setConfirmOpen(false);
							}}
						>
							Yes
						</button>
						<button
							className="bg-red-500 text-white px-4 py-2 rounded-md text-lg"
							onClick={() => setConfirmOpen(false)}
						>
							No
						</button>
					</div>
				</div>
			)}
		</>
	);
}

export default HiddenButton;
