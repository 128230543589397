import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Advance, RedBone, TapToPlay } from '../assets/assets';
import Blobs from '../components/Blobs';

function LandingPage() {
	const navigate = useNavigate();

	return (
		<div className="w-screen h-screen overflow-hidden flex flex-col items-center justify-center gap-8 relative">
			<Blobs />
			{/* Main Title */}
			<div className="w-full flex justify-start grow-0">
				<img className="" src={TapToPlay} alt="" />
			</div>
			{/* Play Button */}
			<div className="w-full flex justify-center">
				<img
					className="w-[55%] animate-landing-shake-bone grow-0"
					src={RedBone}
					alt=""
					onClick={() => navigate('/pick-a-bone', { replace: true })}
				/>
				<h1
					className="absolute top-1/2 left-1/2
							text-white text-[80px] font-larrseit italic pointer-events-none animate-landing-shake-text"
				>
					Play
				</h1>
			</div>
			{/* Logo */}
			<img className="grow-0" src={Advance} alt="" />
		</div>
	);
}

export default LandingPage;
