import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PickABoneApp from './pages/PickABoneApp';
import { useEffect } from 'react';

function App() {
	function getMachineId() {
		let machineId = localStorage.getItem('MachineId');

		if (!machineId) {
			machineId = crypto.randomUUID();
			localStorage.setItem('MachineId', machineId);
		}

		return machineId;
	}

	useEffect(() => {
		getMachineId();
	}, []);

	return (
		<Router>
			<Routes>
				<Route index path="/*" element={<PickABoneApp />} />
			</Routes>
		</Router>
	);
}

export default App;
