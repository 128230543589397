import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import SignUpPage from './SignUpPage';
import PickPage from './PickPage';
import WinnerPage from './WinnerPage';

function PickABoneApp() {
	return (
		<div className="w-screen h-screen overflow-hidden relative">
			<Routes>
				<Route path="/" element={<LandingPage />} />
				{/* <Route path="/signup" element={<SignUpPage />} /> */}
				<Route path="pick-a-bone" element={<PickPage />} />
				<Route path="winner" element={<WinnerPage />} />
			</Routes>
		</div>
	);
}

export default PickABoneApp;
