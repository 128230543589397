import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	DarkBlueBone,
	DotsLeft,
	DotsRight,
	LightBlueBone,
	PickABoneText,
	RedBone,
	WhiteBone,
	YellowBone,
} from '../assets/assets';
import Blobs from '../components/Blobs';
import HiddenButton from '../components/HiddenButton';
import { AiOutlineLoading } from 'react-icons/ai';

function PickPage() {
	const navigate = useNavigate();

	const bones = [
		{ bone: YellowBone, animation: 'animate-shake-one' },
		{ bone: DarkBlueBone, animation: 'animate-shake-three' },
		{ bone: WhiteBone, animation: 'animate-shake-two' },
		{ bone: WhiteBone, animation: 'animate-shake-two' },
		{ bone: RedBone, animation: 'animate-shake-one' },
		{ bone: LightBlueBone, animation: 'animate-shake-three' },
		{ bone: DarkBlueBone, animation: 'animate-shake-three' },
		{ bone: YellowBone, animation: 'animate-shake-one' },
		{ bone: DarkBlueBone, animation: 'animate-shake-two' },
	];

	const noPrizesLeft = 'No unclaimed prizes currently available';

	const getPrize = async () => {
		// const headers = new Headers();
		// headers.append('Content-Type', 'text/plain');
		// const requestOptions = {
		// 	method: 'POST',
		// 	headers: headers,
		// 	body: JSON.stringify({ deviceId: localStorage.getItem('MachineId') }),
		// 	redirect: 'follow',
		// };
		// setLoading(true);
		// fetch(
		// 	'https://dpf782fab1.execute-api.ap-southeast-2.amazonaws.com/default/advance-easter-show-23',
		// 	requestOptions
		// )
		// 	.then((response) => response.text())
		// 	.then((result) => {
		// 		const resultJSON = JSON.parse(result);
		// 		if (resultJSON['Result'] && resultJSON['Result'] === noPrizesLeft) {
		// 			setErrorModalOpen(true);
		// 			setErrorModalText(noPrizesLeft);
		// 		} else if (resultJSON['prizeDetails']) {
		// 			navigate('/winner', { replace: true, state: { prizeDetails: resultJSON['prizeDetails'] } });
		// 		}
		// 	})
		// 	.catch((_) => {
		// 		setErrorModalOpen(true);
		// 		setErrorModalText('Unexpected error has occured');
		// 	})
		// 	.finally(() => setLoading(false));

		setLoading(true);

		const prizes = [
			"Phone Grip",
			"Poo Bag",
			"Mars Bar"
		]

		setTimeout(() => {
			const index =  Math.floor(Math.random() * 3);
			const prize = prizes[index];
			navigate('/winner', { replace: true, state: { prizeDetails: prize } });
		}, 1000)
	};

	const [loading, setLoading] = useState(false);

	const [errorModalOpen, setErrorModalOpen] = useState(false);
	const [errorModalText, setErrorModalText] = useState('');

	return (
		<>
			{loading && (
				<div className="z-50 pointer-events-none fixed top-0 left-0 h-full w-full flex items-center justify-center bg-black/30">
					<AiOutlineLoading className="text-[100px] animate-spin text-[rgb(220,222,228)]" />
				</div>
			)}
			<div
				className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border-2 border-red-500 p-4 bg-white rounded-md z-10 shadow ${
					errorModalOpen ? 'visible' : 'hidden'
				} flex flex-col items-center gap-4`}
			>
				<p className="text-center whitespace-normal max-w-xs">Error: {errorModalText}</p>
				<button
					className="bg-blue/100 py-2 px-4 border shadow rounded-md bg-red-500 text-white text-xl border-white"
					onClick={() => setErrorModalOpen(false)}
				>
					OK
				</button>
			</div>
			<div className="h-full w-full flex flex-col items-center justify-center gap-8">
				<Blobs leftBlobTop={true} />
				<HiddenButton />
				<div className="flex items-center justify-between w-full -translate-y-4">
					<div className="flex items-start h-full -ml-12 mt-8">
						<img className="" src={DotsLeft} alt="" />
					</div>
					<img className="ml-8" src={PickABoneText} alt="" />
					<div className="flex items-end h-full -mr-16 mb-16">
						<img className="" src={DotsRight} alt="" />
					</div>
				</div>
				<div className="grid grid-cols-3 grid-rows-3 gap-4 w-3/4">
					{bones.map(({ bone, animation }, index) => (
						<img
							key={index}
							className={`w-full transition-all ${animation}`}
							src={bone}
							onClick={() => getPrize()}
							alt=""
						/>
					))}
				</div>
			</div>
		</>
	);
}

export default PickPage;
