import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Apron, Bones, DentalTreat, Hat, Notebook, PhoneGrip, PooBag, ToteBag, Towel, Winner, MarsBar } from '../assets/assets';
import Blobs from '../components/Blobs';
import HiddenButton from '../components/HiddenButton';

let timerID;

function WinnerPage() {
	const location = useLocation();
	const navigate = useNavigate();

	const prizeOptions = {
		Apron: Apron,
		'Dental Treat': DentalTreat,
		Hat: Hat,
		Notebook: Notebook,
		'Phone Grip': PhoneGrip,
		'Poo Bag': PooBag,
		'Tote Bag': ToteBag,
		Towel: Towel,
		'Mars Bar': MarsBar,
	};

	const startTimer = () => {
		if (timerID) cancelTimer(timerID);

		timerID = setTimeout(() => {
			navigate('/', { replace: true });
		}, 5000);
	};

	const cancelTimer = (timer) => {
		clearTimeout(timer);
	};

	useEffect(() => {
		if (!location.state?.prizeDetails) navigate('/', { replace: true });
		document.addEventListener('mousedown', startTimer);
		startTimer();

		return () => {
			document.removeEventListener('mousedown', startTimer);
			cancelTimer(timerID);
		};
	}, []);

	return (
		<div className="w-full h-full flex items-center justify-center">
			<Blobs />
			<HiddenButton />
			{/* Background Bones */}
			<img className="absolute w-4/5 opacity-20 translate-y-12" src={Bones} alt="background bones" />

			{/* Prize */}
			{location.state.prizeDetails && (
				<img
					className="absolute z-10 scale-90 pointer-events-none"
					src={prizeOptions[location.state.prizeDetails]}
					alt="prize image"
				/>
			)}
		</div>
	);
}

export default WinnerPage;
